<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.userName"
            placeholder="用户名"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()"> 查询 </el-button>
          <el-button
            v-if="isAuth('sys:user:save')"
            type="primary"
            @click="addOrUpdateHandle()"
          >
            新增
          </el-button>
          <el-button
            v-if="isAuth('sys:user:delete')"
            type="danger"
            :disabled="dataListSelections.length <= 0"
            @click="deleteHandle()"
          >
            批量删除
          </el-button>
          <el-button
            v-if="isAuth('sys:user:delete')"
            type="danger"
            :disabled="dataListSelections.length <= 0"
            @click="resetHandle()"
          >
            重置密码
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      style="width: 100%"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="用户名"
      />
      <el-table-column
        prop="sex"
        header-align="center"
        align="center"
        width="80"
        label="性别"
      >
        <template slot-scope="scope">
          <span>{{ transDict('SEX', scope.row.sex) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="realName"
        header-align="center"
        align="center"
        label="真实姓名"
      />
      <!--      <el-table-column-->
      <!--        prop="orgNo"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        label="所属机构"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ transOrg(scope.row.orgNo) }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!-- <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司名称"
      /> -->
      <el-table-column
        prop="email"
        header-align="center"
        align="center"
        label="邮箱"
      />
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号"
      />
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        width="80"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small" type="danger">
            禁用
          </el-tag>
          <el-tag
            v-else-if="scope.row.status === 1"
            size="small"
            type="success"
          >
            正常
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="155"
        label="创建时间"
      />
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('sys:user:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.userId)"
          >
            修改
          </el-button>
          <el-button
            v-if="isAuth('sys:user:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.userId)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './user-add-or-update';

export default {
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      searchForm: {
        userName: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/sys/user/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          userName: this.searchForm.userName,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    resetHandle() {
      let userIds = this.dataListSelections.map((item) => {
        return item.userId;
      });
      this.$confirm(`重置后的密码为 888888 ，是否确定？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/sys/user/resetPw',
            method: 'post',
            data: userIds,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    // 删除
    deleteHandle(id) {
      let userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.userId;
          });
      this.$confirm(
        `确定对[id=${userIds.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          this.$http({
            url: '/sys/user/delete',
            method: 'post',
            data: userIds,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
